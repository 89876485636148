import { Injectable } from '@angular/core';
import { LookupData } from '../lookup-data/lookup-data-constant';
import { CurrencyModel } from '../models/currency.model';
import { HttpClient } from "@angular/common/http";
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';

@Injectable({
  providedIn: 'root',
})
export class LookupService {

  constructor(private http: HttpClient) { }

  static getSearchOrderCriteriaLookup() {
    return LookupData.SearchOrderCriteria;
  }
  static getCourseTypeLookup() {
    return LookupData.courseType;
  }
  static getEEDomainLookup() {
    return LookupData.EEDomain;
  }

  getCountryLookup() {
    return this.http.get(apiOf.profile.countryList);
  }

  getDomainLookup() {
    return this.http.get(apiOf.lookUps.IndustryDomain);
  }

  getDisciplineLookup() {
    return this.http.get(apiOf.lookUps.Discipline);
  }

  getInternshipTypeLookup() {
    return this.http.get(apiOf.lookUps.InternshipType);
  }

  getJobTypeLookup() {
    return this.http.get(apiOf.lookUps.JobType);
  }

  getSkillLookup() {
    return this.http.get(apiOf.lookUps.Skills);
  }

  getLanguagesLookup() {
    return this.http.get(apiOf.lookUps.languages);
  }

  getDifficultyLevelLookup() {
    return this.http.get(apiOf.lookUps.dificultyLevel);
  }
  getCertificationsLookup() {
    return this.http.get(apiOf.lookUps.Certifications);
  }

  getJobRolesLookup() {
    return this.http.get(apiOf.lookUps.jobRole);
  }

  getMentorSkills() {
    return this.http.get(apiOf.lookUps.SoftSkill);
  }

  static getCareerlevels() {
    return LookupData.CareerLevels;
  }

  static getBlogCategoryLookup() {
    return LookupData.BlogCategory;
  }

  static getSeminarCategories() {
    return LookupData.SeminarCategories;
  }
  static getCurrencies(): CurrencyModel[] {
    return LookupData.Currencies.map(x => ({...x, 'selected': false}));
  }
}
