export const LookupData = {
  SearchOrderCriteria: [
    {
      name: ' CreationDate',
      id: 1,
      code: 0,
      description: ' CreationDate',
      tooltip: ' CreationDate',
    },
    {
      name: ' View',
      id: 1,
      code: 1,
      description: ' View',
      tooltip: ' View',
    },
    {
      name: ' ScheduledDate',
      id: 1,
      code: 2,
      description: ' ScheduledDate',
      tooltip: ' ScheduledDate',
    },
  ],
  courseType:[
    {
      name: ' Online live',
      id: 1,
      code: 'online',
      description: ' Online live',
      tooltip: ' Online live',
    },
    {
      name: ' Offline',
      id: 2,
      code: 'offline',
      description: ' Offline',
      tooltip: ' Offline',
    },
    {
      name: ' Pre Recorded Video',
      id: 3,
      code: 'videos',
      description: ' Pre Recorded Video',
      tooltip: ' Pre Recorded Video',
    },
  ],
  EEDomain: [
    {
      name: ' ProjectEng',
      id: 1,
      code: 'project',
      description: ' ProjectEng',
      tooltip: ' ProjectEng',
    },
    {
      name: ' KnowEng',
      id: 2,
      code: 'know',
      description: ' KnowEng',
      tooltip: ' KnowEng',
    },
    {
      name: ' CommunityEng',
      id: 3,
      code: 'community',
      description: ' CommunityEng',
      tooltip: ' CommunityEng',
    },
    {
      name: ' LearnEng',
      id: 4,
      code: 'learn',
      description: ' LearnEng',
      tooltip: ' LearnEng',
    },
    {
      name: ' MentorEng',
      id: 5,
      code: 'mentor',
      description: ' MentorEng',
      tooltip: ' MentorEng',
    },
    {
      name: ' SeminarEng',
      id: 6,
      code: 'seminar',
      description: ' SeminarEng',
      tooltip: ' SeminarEng',
    },
  ],
  Country: [
    {
      name: 'Bangladesh',
      id: 106,
      code: 'BD',
      description: 'Dhaka,Bangladesh,AS',
      tooltip: 'Bangladesh,AS',
    },
    {
      name: 'Belgium',
      id: 107,
      code: 'BE',
      description: 'Brussels,Belgium,EU',
      tooltip: 'Belgium,EU',
    },
    {
      name: 'Burkina Faso',
      id: 108,
      code: 'BF',
      description: 'Ouagadouu,Burkina Faso,AF',
      tooltip: 'Burkina Faso,AF',
    },
    {
      name: 'Bulgaria',
      id: 109,
      code: 'BG',
      description: 'Sofia,Bulgaria,EU',
      tooltip: 'Bulgaria,EU',
    },
    {
      name: 'Bosnia and Herzevina',

      id: 110,
      code: 'BA',
      description: 'Sarajevo,Bosnia and Herzevina,EU',
      tooltip: 'Bosnia and Herzevina,EU',
    },
    {
      name: 'Barbados',

      id: 111,
      code: 'BB',
      description: 'Bridgetown,Barbados,NA',
      tooltip: 'Barbados,NA',
    },
    {
      name: 'Wallis and Futuna',

      id: 112,
      code: 'WF',
      description: 'Mata Utu,Wallis and Futuna,OC',
      tooltip: 'Wallis and Futuna,OC',
    },
    {
      name: 'Saint Barthelemy',

      id: 113,
      code: 'BL',
      description: 'Gustavia,Saint Barthelemy,NA',
      tooltip: 'Saint Barthelemy,NA',
    },
    {
      name: 'Bermuda',

      id: 114,
      code: 'BM',
      description: 'Hamilton,Bermuda,NA',
      tooltip: 'Bermuda,NA',
    },
    {
      name: 'Brunei',

      id: 115,
      code: 'BN',
      description: 'Bandar Seri Begawan,Brunei,AS',
      tooltip: 'Brunei,AS',
    },
    {
      name: 'Bolivia',

      id: 116,
      code: 'BO',
      description: 'Sucre,Bolivia,SA',
      tooltip: 'Bolivia,SA',
    },
    {
      name: 'Bahrain',

      id: 117,
      code: 'BH',
      description: 'Manama,Bahrain,AS',
      tooltip: 'Bahrain,AS',
    },
    {
      name: 'Burundi',

      id: 118,
      code: 'BI',
      description: 'Bujumbura,Burundi,AF',
      tooltip: 'Burundi,AF',
    },
    {
      name: 'Benin',

      id: 119,
      code: 'BJ',
      description: 'Porto-Novo,Benin,AF',
      tooltip: 'Benin,AF',
    },
    {
      name: 'Bhutan',

      id: 120,
      code: 'BT',
      description: 'Thimphu,Bhutan,AS',
      tooltip: 'Bhutan,AS',
    },
    {
      name: 'Jamaica',

      id: 121,
      code: 'JM',
      description: 'Kingston,Jamaica,NA',
      tooltip: 'Jamaica,NA',
    },
    {
      name: 'Bouvet Island',

      id: 122,
      code: 'BV',
      description: ',Bouvet Island,AN',
      tooltip: 'Bouvet Island,AN',
    },
    {
      name: 'Botswana',

      id: 123,
      code: 'BW',
      description: 'Gaborone,Botswana,AF',
      tooltip: 'Botswana,AF',
    },
    {
      name: 'Samoa',

      id: 124,
      code: 'WS',
      description: 'Apia,Samoa,OC',
      tooltip: 'Samoa,OC',
    },
    {
      name: 'Bonaire, Saint Eustatius and Saba ',

      id: 125,
      code: 'BQ',
      description: ',Bonaire, Saint Eustatius and Saba ,NA',
      tooltip: 'Bonaire, Saint Eustatius and Saba ,NA',
    },
    {
      name: 'Brazil',

      id: 126,
      code: 'BR',
      description: 'Brasilia,Brazil,SA',
      tooltip: 'Brazil,SA',
    },
    {
      name: 'Bahamas',

      id: 127,
      code: 'BS',
      description: 'Nassau,Bahamas,NA',
      tooltip: 'Bahamas,NA',
    },
    {
      name: 'Jersey',

      id: 128,
      code: 'JE',
      description: 'Saint Helier,Jersey,EU',
      tooltip: 'Jersey,EU',
    },
    {
      name: 'Belarus',

      id: 129,
      code: 'BY',
      description: 'Minsk,Belarus,EU',
      tooltip: 'Belarus,EU',
    },
    {
      name: 'Belize',

      id: 130,
      code: 'BZ',
      description: 'Belmopan,Belize,NA',
      tooltip: 'Belize,NA',
    },
    {
      name: 'Russia',

      id: 131,
      code: 'RU',
      description: 'Moscow,Russia,EU',
      tooltip: 'Russia,EU',
    },
    {
      name: 'Rwanda',

      id: 132,
      code: 'RW',
      description: 'Kigali,Rwanda,AF',
      tooltip: 'Rwanda,AF',
    },
    {
      name: 'Serbia',

      id: 133,
      code: 'RS',
      description: 'Belgrade,Serbia,EU',
      tooltip: 'Serbia,EU',
    },
    {
      name: 'East Timor',

      id: 134,
      code: 'TL',
      description: 'Dili,East Timor,OC',
      tooltip: 'East Timor,OC',
    },
    {
      name: 'Reunion',

      id: 135,
      code: 'RE',
      description: 'Saint-Denis,Reunion,AF',
      tooltip: 'Reunion,AF',
    },
    {
      name: 'Turkmenistan',

      id: 136,
      code: 'TM',
      description: 'Ashgabat,Turkmenistan,AS',
      tooltip: 'Turkmenistan,AS',
    },
    {
      name: 'Tajikistan',

      id: 137,
      code: 'TJ',
      description: 'Dushanbe,Tajikistan,AS',
      tooltip: 'Tajikistan,AS',
    },
    {
      name: 'Romania',

      id: 138,
      code: 'RO',
      description: 'Bucharest,Romania,EU',
      tooltip: 'Romania,EU',
    },
    {
      name: 'Tokelau',

      id: 139,
      code: 'TK',
      description: ',Tokelau,OC',
      tooltip: 'Tokelau,OC',
    },
    {
      name: 'Guinea-Bissau',

      id: 140,
      code: 'GW',
      description: 'Bissau,Guinea-Bissau,AF',
      tooltip: 'Guinea-Bissau,AF',
    },
    {
      name: 'Guam',

      id: 141,
      code: 'GU',
      description: 'Hagatna,Guam,OC',
      tooltip: 'Guam,OC',
    },
    {
      name: 'Guatemala',

      id: 142,
      code: 'GT',
      description: 'Guatemala City,Guatemala,NA',
      tooltip: 'Guatemala,NA',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',

      id: 143,
      code: 'GS',
      description: 'Grytviken,South Georgia and the South Sandwich Islands,AN',
      tooltip: 'South Georgia and the South Sandwich Islands,AN',
    },
    {
      name: 'Greece',

      id: 144,
      code: 'GR',
      description: 'Athens,Greece,EU',
      tooltip: 'Greece,EU',
    },
    {
      name: 'Equatorial Guinea',

      id: 145,
      code: 'GQ',
      description: 'Malabo,Equatorial Guinea,AF',
      tooltip: 'Equatorial Guinea,AF',
    },
    {
      name: 'Guadeloupe',

      id: 146,
      code: 'GP',
      description: 'Basse-Terre,Guadeloupe,NA',
      tooltip: 'Guadeloupe,NA',
    },
    {
      name: 'Japan',

      id: 147,
      code: 'JP',
      description: 'Tokyo,Japan,AS',
      tooltip: 'Japan,AS',
    },
    {
      name: 'Guyana',

      id: 148,
      code: 'GY',
      description: 'Georgetown,Guyana,SA',
      tooltip: 'Guyana,SA',
    },
    {
      name: 'Guernsey',

      id: 149,
      code: 'GG',
      description: 'St Peter Port,Guernsey,EU',
      tooltip: 'Guernsey,EU',
    },
    {
      name: 'French Guiana',

      id: 150,
      code: 'GF',
      description: 'Cayenne,French Guiana,SA',
      tooltip: 'French Guiana,SA',
    },
    {
      name: 'Georgia',

      id: 151,
      code: 'GE',
      description: 'Tbilisi,Georgia,AS',
      tooltip: 'Georgia,AS',
    },
    {
      name: 'Grenada',

      id: 152,
      code: 'GD',
      description: "St. George's,Grenada,NA",
      tooltip: 'Grenada,NA',
    },
    {
      name: 'United Kingdom',

      id: 153,
      code: 'GB',
      description: 'London,United Kingdom,EU',
      tooltip: 'United Kingdom,EU',
    },
    {
      name: 'Gabon',

      id: 154,
      code: 'GA',
      description: 'Libreville,Gabon,AF',
      tooltip: 'Gabon,AF',
    },
    {
      name: 'El Salvador',

      id: 155,
      code: 'SV',
      description: 'San Salvador,El Salvador,NA',
      tooltip: 'El Salvador,NA',
    },
    {
      name: 'Guinea',

      id: 156,
      code: 'GN',
      description: 'Conakry,Guinea,AF',
      tooltip: 'Guinea,AF',
    },
    {
      name: 'Gambia',

      id: 157,
      code: 'GM',
      description: 'Banjul,Gambia,AF',
      tooltip: 'Gambia,AF',
    },
    {
      name: 'Greenland',

      id: 158,
      code: 'GL',
      description: 'Nuuk,Greenland,NA',
      tooltip: 'Greenland,NA',
    },
    {
      name: 'Gibraltar',

      id: 159,
      code: 'GI',
      description: 'Gibraltar,Gibraltar,EU',
      tooltip: 'Gibraltar,EU',
    },
    {
      name: 'Ghana',

      id: 160,
      code: 'GH',
      description: 'Accra,Ghana,AF',
      tooltip: 'Ghana,AF',
    },
    {
      name: 'Oman',

      id: 161,
      code: 'OM',
      description: 'Muscat,Oman,AS',
      tooltip: 'Oman,AS',
    },
    {
      name: 'Tunisia',

      id: 162,
      code: 'TN',
      description: 'Tunis,Tunisia,AF',
      tooltip: 'Tunisia,AF',
    },
    {
      name: 'Jordan',

      id: 163,
      code: 'JO',
      description: 'Amman,Jordan,AS',
      tooltip: 'Jordan,AS',
    },
    {
      name: 'Croatia',

      id: 164,
      code: 'HR',
      description: 'Zagreb,Croatia,EU',
      tooltip: 'Croatia,EU',
    },
    {
      name: 'Haiti',

      id: 165,
      code: 'HT',
      description: 'Port-au-Prince,Haiti,NA',
      tooltip: 'Haiti,NA',
    },
    {
      name: 'Hungary',

      id: 166,
      code: 'HU',
      description: 'Budapest,Hungary,EU',
      tooltip: 'Hungary,EU',
    },
    {
      name: 'Hong Kong',

      id: 167,
      code: 'HK',
      description: 'Hong Kong,Hong Kong,AS',
      tooltip: 'Hong Kong,AS',
    },
    {
      name: 'Honduras',

      id: 168,
      code: 'HN',
      description: 'Tegucigalpa,Honduras,NA',
      tooltip: 'Honduras,NA',
    },
    {
      name: 'Heard Island and McDonald Islands',

      id: 169,
      code: 'HM',
      description: ',Heard Island and McDonald Islands,AN',
      tooltip: 'Heard Island and McDonald Islands,AN',
    },
    {
      name: 'Venezuela',

      id: 170,
      code: 'VE',
      description: 'Caracas,Venezuela,SA',
      tooltip: 'Venezuela,SA',
    },
    {
      name: 'Puerto Rico',

      id: 171,
      code: 'PR',
      description: 'San Juan,Puerto Rico,NA',
      tooltip: 'Puerto Rico,NA',
    },
    {
      name: 'Palestinian Territory',

      id: 172,
      code: 'PS',
      description: 'East Jerusalem,Palestinian Territory,AS',
      tooltip: 'Palestinian Territory,AS',
    },
    {
      name: 'Palau',

      id: 173,
      code: 'PW',
      description: 'Melekeok,Palau,OC',
      tooltip: 'Palau,OC',
    },
    {
      name: 'Portugal',

      id: 174,
      code: 'PT',
      description: 'Lisbon,Portugal,EU',
      tooltip: 'Portugal,EU',
    },
    {
      name: 'Svalbard and Jan Mayen',

      id: 175,
      code: 'SJ',
      description: 'Longyearbyen,Svalbard and Jan Mayen,EU',
      tooltip: 'Svalbard and Jan Mayen,EU',
    },
    {
      name: 'Paraguay',

      id: 176,
      code: 'PY',
      description: 'Asuncion,Paraguay,SA',
      tooltip: 'Paraguay,SA',
    },
    {
      name: 'Iraq',

      id: 177,
      code: 'IQ',
      description: 'Baghdad,Iraq,AS',
      tooltip: 'Iraq,AS',
    },
    {
      name: 'Panama',

      id: 178,
      code: 'PA',
      description: 'Panama City,Panama,NA',
      tooltip: 'Panama,NA',
    },
    {
      name: 'French Polynesia',

      id: 179,
      code: 'PF',
      description: 'Papeete,French Polynesia,OC',
      tooltip: 'French Polynesia,OC',
    },
    {
      name: 'Papua New Guinea',

      id: 180,
      code: 'PG',
      description: 'Port Moresby,Papua New Guinea,OC',
      tooltip: 'Papua New Guinea,OC',
    },
    {
      name: 'Peru',

      id: 181,
      code: 'PE',
      description: 'Lima,Peru,SA',
      tooltip: 'Peru,SA',
    },
    {
      name: 'Pakistan',

      id: 182,
      code: 'PK',
      description: 'Islamabad,Pakistan,AS',
      tooltip: 'Pakistan,AS',
    },
    {
      name: 'Philippines',

      id: 183,
      code: 'PH',
      description: 'Manila,Philippines,AS',
      tooltip: 'Philippines,AS',
    },
    {
      name: 'Pitcairn',

      id: 184,
      code: 'PN',
      description: 'Adamstown,Pitcairn,OC',
      tooltip: 'Pitcairn,OC',
    },
    {
      name: 'Poland',

      id: 185,
      code: 'PL',
      description: 'Warsaw,Poland,EU',
      tooltip: 'Poland,EU',
    },
    {
      name: 'Saint Pierre and Miquelon',

      id: 186,
      code: 'PM',
      description: 'Saint-Pierre,Saint Pierre and Miquelon,NA',
      tooltip: 'Saint Pierre and Miquelon,NA',
    },
    {
      name: 'Zambia',

      id: 187,
      code: 'ZM',
      description: 'Lusaka,Zambia,AF',
      tooltip: 'Zambia,AF',
    },
    {
      name: 'Western Sahara',

      id: 188,
      code: 'EH',
      description: 'El-Aaiun,Western Sahara,AF',
      tooltip: 'Western Sahara,AF',
    },
    {
      name: 'Estonia',

      id: 189,
      code: 'EE',
      description: 'Tallinn,Estonia,EU',
      tooltip: 'Estonia,EU',
    },
    {
      name: 'Egypt',

      id: 190,
      code: 'EG',
      description: 'Cairo,Egypt,AF',
      tooltip: 'Egypt,AF',
    },
    {
      name: 'South Africa',

      id: 191,
      code: 'ZA',
      description: 'Pretoria,South Africa,AF',
      tooltip: 'South Africa,AF',
    },
    {
      name: 'Ecuador',

      id: 192,
      code: 'EC',
      description: 'Quito,Ecuador,SA',
      tooltip: 'Ecuador,SA',
    },
    {
      name: 'Italy',

      id: 193,
      code: 'IT',
      description: 'Rome,Italy,EU',
      tooltip: 'Italy,EU',
    },
    {
      name: 'Vietnam',

      id: 194,
      code: 'VN',
      description: 'Hanoi,Vietnam,AS',
      tooltip: 'Vietnam,AS',
    },
    {
      name: 'Solomon Islands',

      id: 195,
      code: 'SB',
      description: 'Honiara,Solomon Islands,OC',
      tooltip: 'Solomon Islands,OC',
    },
    {
      name: 'Ethiopia',

      id: 196,
      code: 'ET',
      description: 'Addis Ababa,Ethiopia,AF',
      tooltip: 'Ethiopia,AF',
    },
    {
      name: 'Somalia',

      id: 197,
      code: 'SO',
      description: 'Mogadishu,Somalia,AF',
      tooltip: 'Somalia,AF',
    },
    {
      name: 'Zimbabwe',

      id: 198,
      code: 'ZW',
      description: 'Harare,Zimbabwe,AF',
      tooltip: 'Zimbabwe,AF',
    },
    {
      name: 'Saudi Arabia',

      id: 199,
      code: 'SA',
      description: 'Riyadh,Saudi Arabia,AS',
      tooltip: 'Saudi Arabia,AS',
    },
    {
      name: 'Spain',

      id: 200,
      code: 'ES',
      description: 'Madrid,Spain,EU',
      tooltip: 'Spain,EU',
    },
    {
      name: 'Eritrea',

      id: 201,
      code: 'ER',
      description: 'Asmara,Eritrea,AF',
      tooltip: 'Eritrea,AF',
    },
    {
      name: 'Montenegro',

      id: 202,
      code: 'ME',
      description: 'Podrica,Montenegro,EU',
      tooltip: 'Montenegro,EU',
    },
    {
      name: 'Moldova',

      id: 203,
      code: 'MD',
      description: 'Chisinau,Moldova,EU',
      tooltip: 'Moldova,EU',
    },
    {
      name: 'Madagascar',

      id: 204,
      code: 'MG',
      description: 'Antananarivo,Madagascar,AF',
      tooltip: 'Madagascar,AF',
    },
    {
      name: 'Saint Martin',

      id: 205,
      code: 'MF',
      description: 'Marit,Saint Martin,NA',
      tooltip: 'Saint Martin,NA',
    },
    {
      name: 'Morocco',

      id: 206,
      code: 'MA',
      description: 'Rabat,Morocco,AF',
      tooltip: 'Morocco,AF',
    },
    {
      name: 'Monaco',

      id: 207,
      code: 'MC',
      description: 'Monaco,Monaco,EU',
      tooltip: 'Monaco,EU',
    },
    {
      name: 'Uzbekistan',

      id: 208,
      code: 'UZ',
      description: 'Tashkent,Uzbekistan,AS',
      tooltip: 'Uzbekistan,AS',
    },
    {
      name: 'Myanmar',

      id: 209,
      code: 'MM',
      description: 'Nay Pyi Taw,Myanmar,AS',
      tooltip: 'Myanmar,AS',
    },
    {
      name: 'Mali',

      id: 210,
      code: 'ML',
      description: 'Bamako,Mali,AF',
      tooltip: 'Mali,AF',
    },
    {
      name: 'Macao',

      id: 211,
      code: 'MO',
      description: 'Macao,Macao,AS',
      tooltip: 'Macao,AS',
    },
    {
      name: 'Monlia',

      id: 212,
      code: 'MN',
      description: 'Ulan Bator,Monlia,AS',
      tooltip: 'Monlia,AS',
    },
    {
      name: 'Marshall Islands',

      id: 213,
      code: 'MH',
      description: 'Majuro,Marshall Islands,OC',
      tooltip: 'Marshall Islands,OC',
    },
    {
      name: 'Macedonia',

      id: 214,
      code: 'MK',
      description: 'Skopje,Macedonia,EU',
      tooltip: 'Macedonia,EU',
    },
    {
      name: 'Mauritius',

      id: 215,
      code: 'MU',
      description: 'Port Louis,Mauritius,AF',
      tooltip: 'Mauritius,AF',
    },
    {
      name: 'Malta',

      id: 216,
      code: 'MT',
      description: 'Valletta,Malta,EU',
      tooltip: 'Malta,EU',
    },
    {
      name: 'Malawi',

      id: 217,
      code: 'MW',
      description: 'Lilongwe,Malawi,AF',
      tooltip: 'Malawi,AF',
    },
    {
      name: 'Maldives',

      id: 218,
      code: 'MV',
      description: 'Male,Maldives,AS',
      tooltip: 'Maldives,AS',
    },
    {
      name: 'Martinique',

      id: 219,
      code: 'MQ',
      description: 'Fort-de-France,Martinique,NA',
      tooltip: 'Martinique,NA',
    },
    {
      name: 'Northern Mariana Islands',

      id: 220,
      code: 'MP',
      description: 'Saipan,Northern Mariana Islands,OC',
      tooltip: 'Northern Mariana Islands,OC',
    },
    {
      name: 'Montserrat',

      id: 221,
      code: 'MS',
      description: 'Plymouth,Montserrat,NA',
      tooltip: 'Montserrat,NA',
    },
    {
      name: 'Mauritania',

      id: 222,
      code: 'MR',
      description: 'Nouakchott,Mauritania,AF',
      tooltip: 'Mauritania,AF',
    },
    {
      name: 'Isle of Man',

      id: 223,
      code: 'IM',
      description: 'Douglas, Isle of Man,Isle of Man,EU',
      tooltip: 'Isle of Man,EU',
    },
    {
      name: 'Uganda',

      id: 224,
      code: 'UG',
      description: 'Kampala,Uganda,AF',
      tooltip: 'Uganda,AF',
    },
    {
      name: 'Tanzania',

      id: 225,
      code: 'TZ',
      description: 'Dodoma,Tanzania,AF',
      tooltip: 'Tanzania,AF',
    },
    {
      name: 'Malaysia',

      id: 226,
      code: 'MY',
      description: 'Kuala Lumpur,Malaysia,AS',
      tooltip: 'Malaysia,AS',
    },
    {
      name: 'Mexico',

      id: 227,
      code: 'MX',
      description: 'Mexico City,Mexico,NA',
      tooltip: 'Mexico,NA',
    },
    {
      name: 'Israel',

      id: 228,
      code: 'IL',
      description: 'Jerusalem,Israel,AS',
      tooltip: 'Israel,AS',
    },
    {
      name: 'France',

      id: 229,
      code: 'FR',
      description: 'Paris,France,EU',
      tooltip: 'France,EU',
    },
    {
      name: 'British Indian Ocean Territory',

      id: 230,
      code: 'IO',
      description: 'Die Garcia,British Indian Ocean Territory,AS',
      tooltip: 'British Indian Ocean Territory,AS',
    },
    {
      name: 'Saint Helena',

      id: 231,
      code: 'SH',
      description: 'Jamestown,Saint Helena,AF',
      tooltip: 'Saint Helena,AF',
    },
    {
      name: 'Finland',

      id: 232,
      code: 'FI',
      description: 'Helsinki,Finland,EU',
      tooltip: 'Finland,EU',
    },
    {
      name: 'Fiji',

      id: 233,
      code: 'FJ',
      description: 'Suva,Fiji,OC',
      tooltip: 'Fiji,OC',
    },
    {
      name: 'Falkland Islands',

      id: 234,
      code: 'FK',
      description: 'Stanley,Falkland Islands,SA',
      tooltip: 'Falkland Islands,SA',
    },
    {
      name: 'Micronesia',

      id: 235,
      code: 'FM',
      description: 'Palikir,Micronesia,OC',
      tooltip: 'Micronesia,OC',
    },
    {
      name: 'Faroe Islands',

      id: 236,
      code: 'FO',
      description: 'Torshavn,Faroe Islands,EU',
      tooltip: 'Faroe Islands,EU',
    },
    {
      name: 'Nicaragua',

      id: 237,
      code: 'NI',
      description: 'Managua,Nicaragua,NA',
      tooltip: 'Nicaragua,NA',
    },
    {
      name: 'Netherlands',

      id: 238,
      code: 'NL',
      description: 'Amsterdam,Netherlands,EU',
      tooltip: 'Netherlands,EU',
    },
    {
      name: 'Norway',

      id: 239,
      code: 'NO',
      description: 'Oslo,Norway,EU',
      tooltip: 'Norway,EU',
    },
    {
      name: 'Namibia',

      id: 240,
      code: 'NA',
      description: 'Windhoek,Namibia,AF',
      tooltip: 'Namibia,AF',
    },
    {
      name: 'Vanuatu',

      id: 241,
      code: 'VU',
      description: 'Port Vila,Vanuatu,OC',
      tooltip: 'Vanuatu,OC',
    },
    {
      name: 'New Caledonia',

      id: 242,
      code: 'NC',
      description: 'Noumea,New Caledonia,OC',
      tooltip: 'New Caledonia,OC',
    },
    {
      name: 'Niger',

      id: 243,
      code: 'NE',
      description: 'Niamey,Niger,AF',
      tooltip: 'Niger,AF',
    },
    {
      name: 'Norfolk Island',

      id: 244,
      code: 'NF',
      description: 'Kingston,Norfolk Island,OC',
      tooltip: 'Norfolk Island,OC',
    },
    {
      name: 'Nigeria',

      id: 245,
      code: 'NG',
      description: 'Abuja,Nigeria,AF',
      tooltip: 'Nigeria,AF',
    },
    {
      name: 'New Zealand',

      id: 246,
      code: 'NZ',
      description: 'Wellington,New Zealand,OC',
      tooltip: 'New Zealand,OC',
    },
    {
      name: 'Nepal',

      id: 247,
      code: 'NP',
      description: 'Kathmandu,Nepal,AS',
      tooltip: 'Nepal,AS',
    },
    {
      name: 'Nauru',

      id: 248,
      code: 'NR',
      description: 'Yaren,Nauru,OC',
      tooltip: 'Nauru,OC',
    },
    {
      name: 'Niue',

      id: 249,
      code: 'NU',
      description: 'Alofi,Niue,OC',
      tooltip: 'Niue,OC',
    },
    {
      name: 'Cook Islands',

      id: 250,
      code: 'CK',
      description: 'Avarua,Cook Islands,OC',
      tooltip: 'Cook Islands,OC',
    },
    {
      name: 'Kosovo',

      id: 251,
      code: 'XK',
      description: 'Pristina,Kosovo,EU',
      tooltip: 'Kosovo,EU',
    },
    {
      name: 'Ivory Coast',

      id: 252,
      code: 'CI',
      description: 'Yamoussoukro,Ivory Coast,AF',
      tooltip: 'Ivory Coast,AF',
    },
    {
      name: 'Switzerland',

      id: 253,
      code: 'CH',
      description: 'Berne,Switzerland,EU',
      tooltip: 'Switzerland,EU',
    },
    {
      name: 'Colombia',

      id: 254,
      code: 'CO',
      description: 'Bota,Colombia,SA',
      tooltip: 'Colombia,SA',
    },
    {
      name: 'China',

      id: 255,
      code: 'CN',
      description: 'Beijing,China,AS',
      tooltip: 'China,AS',
    },
    {
      name: 'Cameroon',

      id: 256,
      code: 'CM',
      description: 'Yaounde,Cameroon,AF',
      tooltip: 'Cameroon,AF',
    },
    {
      name: 'Chile',

      id: 257,
      code: 'CL',
      description: 'Santia,Chile,SA',
      tooltip: 'Chile,SA',
    },
    {
      name: 'Cocos Islands',

      id: 258,
      code: 'CC',
      description: 'West Island,Cocos Islands,AS',
      tooltip: 'Cocos Islands,AS',
    },
    {
      name: 'Canada',

      id: 259,
      code: 'CA',
      description: 'Ottawa,Canada,NA',
      tooltip: 'Canada,NA',
    },
    {
      name: 'Republic of the Con',

      id: 260,
      code: 'CG',
      description: 'Brazzaville,Republic of the Con,AF',
      tooltip: 'Republic of the Con,AF',
    },
    {
      name: 'Central African Republic',

      id: 261,
      code: 'CF',
      description: 'Bangui,Central African Republic,AF',
      tooltip: 'Central African Republic,AF',
    },
    {
      name: 'Democratic Republic of the Con',

      id: 262,
      code: 'CD',
      description: 'Kinshasa,Democratic Republic of the Con,AF',
      tooltip: 'Democratic Republic of the Con,AF',
    },
    {
      name: 'Czech Republic',

      id: 263,
      code: 'CZ',
      description: 'Prague,Czech Republic,EU',
      tooltip: 'Czech Republic,EU',
    },
    {
      name: 'Cyprus',

      id: 264,
      code: 'CY',
      description: 'Nicosia,Cyprus,EU',
      tooltip: 'Cyprus,EU',
    },
    {
      name: 'Christmas Island',

      id: 265,
      code: 'CX',
      description: 'Flying Fish Cove,Christmas Island,AS',
      tooltip: 'Christmas Island,AS',
    },
    {
      name: 'Costa Rica',

      id: 266,
      code: 'CR',
      description: 'San Jose,Costa Rica,NA',
      tooltip: 'Costa Rica,NA',
    },
    {
      name: 'Curacao',

      id: 267,
      code: 'CW',
      description: ' Willemstad,Curacao,NA',
      tooltip: 'Curacao,NA',
    },
    {
      name: 'Cape Verde',

      id: 268,
      code: 'CV',
      description: 'Praia,Cape Verde,AF',
      tooltip: 'Cape Verde,AF',
    },
    {
      name: 'Cuba',

      id: 269,
      code: 'CU',
      description: 'Havana,Cuba,NA',
      tooltip: 'Cuba,NA',
    },
    {
      name: 'Swaziland',

      id: 270,
      code: 'SZ',
      description: 'Mbabane,Swaziland,AF',
      tooltip: 'Swaziland,AF',
    },
    {
      name: 'Syria',

      id: 271,
      code: 'SY',
      description: 'Damascus,Syria,AS',
      tooltip: 'Syria,AS',
    },
    {
      name: 'Sint Maarten',

      id: 272,
      code: 'SX',
      description: 'Philipsburg,Sint Maarten,NA',
      tooltip: 'Sint Maarten,NA',
    },
    {
      name: 'Kyrgyzstan',

      id: 273,
      code: 'KG',
      description: 'Bishkek,Kyrgyzstan,AS',
      tooltip: 'Kyrgyzstan,AS',
    },
    {
      name: 'Kenya',

      id: 274,
      code: 'KE',
      description: 'Nairobi,Kenya,AF',
      tooltip: 'Kenya,AF',
    },
    {
      name: 'South Sudan',

      id: 275,
      code: 'SS',
      description: 'Juba,South Sudan,AF',
      tooltip: 'South Sudan,AF',
    },
    {
      name: 'Suriname',

      id: 276,
      code: 'SR',
      description: 'Paramaribo,Suriname,SA',
      tooltip: 'Suriname,SA',
    },
    {
      name: 'Kiribati',

      id: 277,
      code: 'KI',
      description: 'Tarawa,Kiribati,OC',
      tooltip: 'Kiribati,OC',
    },
    {
      name: 'Cambodia',

      id: 278,
      code: 'KH',
      description: 'Phnom Penh,Cambodia,AS',
      tooltip: 'Cambodia,AS',
    },
    {
      name: 'Saint Kitts and Nevis',

      id: 279,
      code: 'KN',
      description: 'Basseterre,Saint Kitts and Nevis,NA',
      tooltip: 'Saint Kitts and Nevis,NA',
    },
    {
      name: 'Comoros',

      id: 280,
      code: 'KM',
      description: 'Moroni,Comoros,AF',
      tooltip: 'Comoros,AF',
    },
    {
      name: 'Sao Tome and Principe',

      id: 281,
      code: 'ST',
      description: 'Sao Tome,Sao Tome and Principe,AF',
      tooltip: 'Sao Tome and Principe,AF',
    },
    {
      name: 'Slovakia',

      id: 282,
      code: 'SK',
      description: 'Bratislava,Slovakia,EU',
      tooltip: 'Slovakia,EU',
    },
    {
      name: 'South Korea',

      id: 283,
      code: 'KR',
      description: 'Seoul,South Korea,AS',
      tooltip: 'South Korea,AS',
    },
    {
      name: 'Slovenia',

      id: 284,
      code: 'SI',
      description: 'Ljubljana,Slovenia,EU',
      tooltip: 'Slovenia,EU',
    },
    {
      name: 'North Korea',

      id: 285,
      code: 'KP',
      description: 'Pyongyang,North Korea,AS',
      tooltip: 'North Korea,AS',
    },
    {
      name: 'Kuwait',

      id: 286,
      code: 'KW',
      description: 'Kuwait City,Kuwait,AS',
      tooltip: 'Kuwait,AS',
    },
    {
      name: 'Senegal',

      id: 287,
      code: 'SN',
      description: 'Dakar,Senegal,AF',
      tooltip: 'Senegal,AF',
    },
    {
      name: 'San Marino',

      id: 288,
      code: 'SM',
      description: 'San Marino,San Marino,EU',
      tooltip: 'San Marino,EU',
    },
    {
      name: 'Sierra Leone',

      id: 289,
      code: 'SL',
      description: 'Freetown,Sierra Leone,AF',
      tooltip: 'Sierra Leone,AF',
    },
    {
      name: 'Seychelles',

      id: 290,
      code: 'SC',
      description: 'Victoria,Seychelles,AF',
      tooltip: 'Seychelles,AF',
    },
    {
      name: 'Kazakhstan',

      id: 291,
      code: 'KZ',
      description: 'Astana,Kazakhstan,AS',
      tooltip: 'Kazakhstan,AS',
    },
    {
      name: 'Cayman Islands',

      id: 292,
      code: 'KY',
      description: 'George Town,Cayman Islands,NA',
      tooltip: 'Cayman Islands,NA',
    },
    {
      name: 'Singapore',

      id: 293,
      code: 'SG',
      description: 'Singapur,Singapore,AS',
      tooltip: 'Singapore,AS',
    },
    {
      name: 'Sweden',

      id: 294,
      code: 'SE',
      description: 'Stockholm,Sweden,EU',
      tooltip: 'Sweden,EU',
    },
    {
      name: 'Sudan',

      id: 295,
      code: 'SD',
      description: 'Khartoum,Sudan,AF',
      tooltip: 'Sudan,AF',
    },
    {
      name: 'Dominican Republic',

      id: 296,
      code: 'DO',
      description: 'Santo Domin,Dominican Republic,NA',
      tooltip: 'Dominican Republic,NA',
    },
    {
      name: 'Dominica',

      id: 297,
      code: 'DM',
      description: 'Roseau,Dominica,NA',
      tooltip: 'Dominica,NA',
    },
    {
      name: 'Djibouti',

      id: 298,
      code: 'DJ',
      description: 'Djibouti,Djibouti,AF',
      tooltip: 'Djibouti,AF',
    },
    {
      name: 'Denmark',

      id: 299,
      code: 'DK',
      description: 'Copenhagen,Denmark,EU',
      tooltip: 'Denmark,EU',
    },
    {
      name: 'British Virgin Islands',

      id: 300,
      code: 'VG',
      description: 'Road Town,British Virgin Islands,NA',
      tooltip: 'British Virgin Islands,NA',
    },
    {
      name: 'Germany',

      id: 301,
      code: 'DE',
      description: 'Berlin,Germany,EU',
      tooltip: 'Germany,EU',
    },
    {
      name: 'Yemen',

      id: 302,
      code: 'YE',
      description: 'Sanaa,Yemen,AS',
      tooltip: 'Yemen,AS',
    },
    {
      name: 'Algeria',

      id: 303,
      code: 'DZ',
      description: 'Algiers,Algeria,AF',
      tooltip: 'Algeria,AF',
    },
    {
      name: 'United States',

      id: 304,
      code: 'US',
      description: 'Washington,United States,NA',
      tooltip: 'United States,NA',
    },
    {
      name: 'Uruguay',

      id: 305,
      code: 'UY',
      description: 'Montevideo,Uruguay,SA',
      tooltip: 'Uruguay,SA',
    },
    {
      name: 'Mayotte',

      id: 306,
      code: 'YT',
      description: 'Mamoudzou,Mayotte,AF',
      tooltip: 'Mayotte,AF',
    },
    {
      name: 'United States Minor Outlying Islands',

      id: 307,
      code: 'UM',
      description: ',United States Minor Outlying Islands,OC',
      tooltip: 'United States Minor Outlying Islands,OC',
    },
    {
      name: 'Lebanon',

      id: 308,
      code: 'LB',
      description: 'Beirut,Lebanon,AS',
      tooltip: 'Lebanon,AS',
    },
    {
      name: 'Saint Lucia',

      id: 309,
      code: 'LC',
      description: 'Castries,Saint Lucia,NA',
      tooltip: 'Saint Lucia,NA',
    },
    {
      name: 'Laos',

      id: 310,
      code: 'LA',
      description: 'Vientiane,Laos,AS',
      tooltip: 'Laos,AS',
    },
    {
      name: 'Tuvalu',

      id: 311,
      code: 'TV',
      description: 'Funafuti,Tuvalu,OC',
      tooltip: 'Tuvalu,OC',
    },
    {
      name: 'Taiwan',

      id: 312,
      code: 'TW',
      description: 'Taipei,Taiwan,AS',
      tooltip: 'Taiwan,AS',
    },
    {
      name: 'Trinidad and Toba',

      id: 313,
      code: 'TT',
      description: 'Port of Spain,Trinidad and Toba,NA',
      tooltip: 'Trinidad and Toba,NA',
    },
    {
      name: 'Turkey',

      id: 314,
      code: 'TR',
      description: 'Ankara,Turkey,AS',
      tooltip: 'Turkey,AS',
    },
    {
      name: 'Sri Lanka',

      id: 315,
      code: 'LK',
      description: 'Colombo,Sri Lanka,AS',
      tooltip: 'Sri Lanka,AS',
    },
    {
      name: 'Liechtenstein',

      id: 316,
      code: 'LI',
      description: 'Vaduz,Liechtenstein,EU',
      tooltip: 'Liechtenstein,EU',
    },
    {
      name: 'Latvia',

      id: 317,
      code: 'LV',
      description: 'Riga,Latvia,EU',
      tooltip: 'Latvia,EU',
    },
    {
      name: 'Tonga',

      id: 318,
      code: 'TO',
      description: "Nuku'alofa,Tonga,OC",
      tooltip: 'Tonga,OC',
    },
    {
      name: 'Lithuania',

      id: 319,
      code: 'LT',
      description: 'Vilnius,Lithuania,EU',
      tooltip: 'Lithuania,EU',
    },
    {
      name: 'Luxembourg',

      id: 320,
      code: 'LU',
      description: 'Luxembourg,Luxembourg,EU',
      tooltip: 'Luxembourg,EU',
    },
    {
      name: 'Liberia',

      id: 321,
      code: 'LR',
      description: 'Monrovia,Liberia,AF',
      tooltip: 'Liberia,AF',
    },
    {
      name: 'Lesotho',

      id: 322,
      code: 'LS',
      description: 'Maseru,Lesotho,AF',
      tooltip: 'Lesotho,AF',
    },
    {
      name: 'Thailand',

      id: 323,
      code: 'TH',
      description: 'Bangkok,Thailand,AS',
      tooltip: 'Thailand,AS',
    },
    {
      name: 'French Southern Territories',

      id: 324,
      code: 'TF',
      description: 'Port-aux-Francais,French Southern Territories,AN',
      tooltip: 'French Southern Territories,AN',
    },
    {
      name: 'To',

      id: 325,
      code: 'TG',
      description: 'Lome,To,AF',
      tooltip: 'To,AF',
    },
    {
      name: 'Chad',

      id: 326,
      code: 'TD',
      description: "N'Djamena,Chad,AF",
      tooltip: 'Chad,AF',
    },
    {
      name: 'Turks and Caicos Islands',

      id: 327,
      code: 'TC',
      description: 'Cockburn Town,Turks and Caicos Islands,NA',
      tooltip: 'Turks and Caicos Islands,NA',
    },
    {
      name: 'Libya',

      id: 328,
      code: 'LY',
      description: 'Tripolis,Libya,AF',
      tooltip: 'Libya,AF',
    },
    {
      name: 'Vatican',

      id: 329,
      code: 'VA',
      description: 'Vatican City,Vatican,EU',
      tooltip: 'Vatican,EU',
    },
    {
      name: 'Saint Vincent and the Grenadines',

      id: 330,
      code: 'VC',
      description: 'Kingstown,Saint Vincent and the Grenadines,NA',
      tooltip: 'Saint Vincent and the Grenadines,NA',
    },
    {
      name: 'United Arab Emirates',

      id: 331,
      code: 'AE',
      description: 'Abu Dhabi,United Arab Emirates,AS',
      tooltip: 'United Arab Emirates,AS',
    },
    {
      name: 'Andorra',

      id: 332,
      code: 'AD',
      description: 'Andorra la Vella,Andorra,EU',
      tooltip: 'Andorra,EU',
    },
    {
      name: 'Antigua and Barbuda',

      id: 333,
      code: 'AG',
      description: "St. John's,Antigua and Barbuda,NA",
      tooltip: 'Antigua and Barbuda,NA',
    },
    {
      name: 'Afghanistan',

      id: 334,
      code: 'AF',
      description: 'Kabul,Afghanistan,AS',
      tooltip: 'Afghanistan,AS',
    },
    {
      name: 'Anguilla',

      id: 335,
      code: 'AI',
      description: 'The Valley,Anguilla,NA',
      tooltip: 'Anguilla,NA',
    },
    {
      name: 'U.S. Virgin Islands',

      id: 336,
      code: 'VI',
      description: 'Charlotte Amalie,U.S. Virgin Islands,NA',
      tooltip: 'U.S. Virgin Islands,NA',
    },
    {
      name: 'Iceland',

      id: 337,
      code: 'IS',
      description: 'Reykjavik,Iceland,EU',
      tooltip: 'Iceland,EU',
    },
    {
      name: 'Iran',

      id: 338,
      code: 'IR',
      description: 'Tehran,Iran,AS',
      tooltip: 'Iran,AS',
    },
    {
      name: 'Armenia',

      id: 339,
      code: 'AM',
      description: 'Yerevan,Armenia,AS',
      tooltip: 'Armenia,AS',
    },
    {
      name: 'Albania',

      id: 340,
      code: 'AL',
      description: 'Tirana,Albania,EU',
      tooltip: 'Albania,EU',
    },
    {
      name: 'Anla',

      id: 341,
      code: 'AO',
      description: 'Luanda,Anla,AF',
      tooltip: 'Anla,AF',
    },
    {
      name: 'Antarctica',

      id: 342,
      code: 'AQ',
      description: ',Antarctica,AN',
      tooltip: 'Antarctica,AN',
    },
    {
      name: 'American Samoa',

      id: 343,
      code: 'AS',
      description: 'Pa Pa,American Samoa,OC',
      tooltip: 'American Samoa,OC',
    },
    {
      name: 'Argentina',

      id: 344,
      code: 'AR',
      description: 'Buenos Aires,Argentina,SA',
      tooltip: 'Argentina,SA',
    },
    {
      name: 'Australia',

      id: 345,
      code: 'AU',
      description: 'Canberra,Australia,OC',
      tooltip: 'Australia,OC',
    },
    {
      name: 'Austria',

      id: 346,
      code: 'AT',
      description: 'Vienna,Austria,EU',
      tooltip: 'Austria,EU',
    },
    {
      name: 'Aruba',

      id: 347,
      code: 'AW',
      description: 'Oranjestad,Aruba,NA',
      tooltip: 'Aruba,NA',
    },
    {
      name: 'India',

      id: 348,
      code: 'IN',
      description: 'New Delhi,India,AS',
      tooltip: 'India,AS',
    },
    {
      name: 'Aland Islands',

      id: 349,
      code: 'AX',
      description: 'Mariehamn,Aland Islands,EU',
      tooltip: 'Aland Islands,EU',
    },
    {
      name: 'Azerbaijan',

      id: 350,
      code: 'AZ',
      description: 'Baku,Azerbaijan,AS',
      tooltip: 'Azerbaijan,AS',
    },
    {
      name: 'Ireland',

      id: 351,
      code: 'IE',
      description: 'Dublin,Ireland,EU',
      tooltip: 'Ireland,EU',
    },
    {
      name: 'Indonesia',

      id: 352,
      code: 'ID',
      description: 'Jakarta,Indonesia,AS',
      tooltip: 'Indonesia,AS',
    },
    {
      name: 'Ukraine',

      id: 353,
      code: 'UA',
      description: 'Kiev,Ukraine,EU',
      tooltip: 'Ukraine,EU',
    },
    {
      name: 'Qatar',

      id: 354,
      code: 'QA',
      description: 'Doha,Qatar,AS',
      tooltip: 'Qatar,AS',
    },
    {
      name: 'Mozambique',

      id: 355,
      code: 'MZ',
      description: 'Maputo,Mozambique,AF',
      tooltip: 'Mozambique,AF',
    },
  ],
  Domain: [
    {
      name: ' Oil & Gas',
      id: 40,
      code: 'oil&gas',
      description: ' Oil & Gas',
      tooltip: ' Oil & Gas',
    },
    {
      name: 'Energy & Utilities',
      id: 41,
      code: 'energy&utilities',
      description: 'Energy & Utilities',
      tooltip: 'Energy & Utilities',
    },
    {
      name: 'Aerospace',
      id: 42,
      code: 'aerospace',
      description: 'Aerospace',
      tooltip: 'Aerospace',
    },
    {
      name: 'Automotive',
      id: 43,
      code: 'automotive',
      description: 'Automotive',
      tooltip: 'Automotive',
    },
    {
      name: 'Marine',
      id: 44,
      code: 'marine',
      description: 'Marine',
      tooltip: 'Marine',
    },
    {
      name: 'Rail & Transport',
      id: 45,
      code: 'rail&transport',
      description: 'Rail & Transport',
      tooltip: 'Rail & Transport',
    },
    {
      name: 'Mechanics & Turbomachinery',
      id: 46,
      code: 'mechanics&turbomachinery',
      description: 'Mechanics & Turbomachinery',
      tooltip: 'Mechanics & Turbomachinery',
    },
    {
      name: 'Household Appliance',
      id: 47,
      code: 'householdappliance',
      description: 'Household Appliance',
      tooltip: 'Household Appliance',
    },
    {
      name: 'Chemical & Pharmaceutical',
      id: 48,
      code: 'chemical&pharmaceutical',
      description: 'Chemical & Pharmaceutical',
      tooltip: 'Chemical & Pharmaceutical',
    },
    {
      name: 'Medical Instruments',
      id: 49,
      code: 'medicalinstruments',
      description: 'Medical Instruments',
      tooltip: 'Medical Instruments',
    },
    {
      name: 'Electronics & Instrumentation',
      id: 50,
      code: 'electronics&instrumentation',
      description: 'Electronics & Instrumentation',
      tooltip: 'Electronics & Instrumentation',
    },
    {
      name: 'Telecommunication',
      id: 51,
      code: 'telecommunication',
      description: 'Telecommunication',
      tooltip: 'Telecommunication',
    },
    {
      name: 'Sports',
      id: 52,
      code: 'sports',
      description: 'Sports',
      tooltip: 'Sports',
    },
    {
      name: 'Furniture',
      id: 53,
      code: 'furniture',
      description: 'Furniture',
      tooltip: 'Furniture',
    },
    {
      name: 'Agriculture',
      id: 54,
      code: 'agriculture',
      description: 'Agriculture',
      tooltip: 'Agriculture',
    },
    {
      name: 'Manufacturing',
      id: 55,
      code: 'manufacturing',
      description: 'Manufacturing',
      tooltip: 'Manufacturing',
    },
    {
      name: 'HVAC&R',
      id: 56,
      code: 'hvacr',
      description: 'HVAC&R',
      tooltip: 'HVAC&R',
    },
    {
      name: 'All Domains',
      id: 57,
      code: 'all',
      description: 'All Domains',
      tooltip: 'All Domains',
    },
  ],
  Discipline: [
    {
      name: 'Mechanical',
      id: 72,
      code: 'mechanical',
      description: 'Mechanical',
      tooltip: 'Mechanical test',
    },
    {
      name: 'Electrical',
      id: 73,
      code: 'electrical',
      description: 'Electrical',
      tooltip: 'test',
    },
    {
      name: 'Chemical',
      id: 74,
      code: 'chemical',
      description: 'Chemical',
      tooltip: 'Chemical',
    },
    {
      name: 'Process',
      id: 75,
      code: 'process',
      description: 'Process',
      tooltip: 'Process',
    },
    {
      name: 'Civil & Structural',
      id: 76,
      code: 'civil&structural',
      description: 'Civil & Structural',
      tooltip: 'Civil & Structural',
    },
    {
      name: 'Piping & Layout',
      id: 77,
      code: 'piping&layout',
      description: 'Piping & Layout',
      tooltip: 'Piping & Layout',
    },
    {
      name: 'Onshore Pipeline',
      id: 78,
      code: 'onshorepipeline',
      description: 'Onshore Pipeline',
      tooltip: 'Onshore Pipeline',
    },
    {
      name: 'Subsea (SURF)',
      id: 79,
      code: 'subsea(surf)',
      description: 'Subsea (SURF)',
      tooltip: 'Subsea (SURF)',
    },
    {
      name: 'Flow Assurance',
      id: 80,
      code: 'flowassurance',
      description: 'Flow Assurance',
      tooltip: 'Flow Assurance',
    },
    {
      name: 'Metallurgy & Material Science',
      id: 81,
      code: 'metallurgy',
      description: 'Metallurgy & Material Science',
      tooltip: 'Metallurgy & Material Science',
    },
    {
      name: 'Petroleum',
      id: 82,
      code: 'petroleum',
      description: 'Petroleum',
      tooltip: 'Petroleum',
    },
    {
      name: 'Geoscience',
      id: 83,
      code: 'geoscience',
      description: 'Geoscience',
      tooltip: 'Geoscience',
    },
    {
      name: 'Environmental',
      id: 84,
      code: 'environmental',
      description: 'Environmental',
      tooltip: 'Environmental',
    },
    {
      name: 'Project Management',
      id: 85,
      code: 'projectmanagement',
      description: 'Project Management',
      tooltip: 'Project Management',
    },
    {
      name: 'Digital Transformation',
      id: 86,
      code: 'digital',
      description: 'Digital Transformation',
      tooltip: 'Digital Transformation',
    },
    {
      name: 'Software Development',
      id: 87,
      code: 'softwaredevelopment',
      description: 'Software Development',
      tooltip: 'Software Development',
    },
    {
      name: 'IT Services',
      id: 88,
      code: 'it',
      description: 'IT Services',
      tooltip: 'IT Services',
    },
    {
      name: 'Electronics',
      id: 89,
      code: 'electronics',
      description: 'Electronics',
      tooltip: 'Electronics',
    },
    {
      name: 'Communication',
      id: 101,
      code: 'communication',
      description: 'Communication',
      tooltip: 'Communication',
    },
    {
      name: 'Marine & Naval',
      id: 91,
      code: 'marine&naval',
      description: 'Marine & Naval',
      tooltip: 'Marine & Naval',
    },
    {
      name: 'Noise & Vibration',
      id: 92,
      code: 'noise&vibration',
      description: 'Noise & Vibration',
      tooltip: 'Noise & Vibration',
    },
    {
      name: 'Data Science & Analysis',
      id: 93,
      code: 'datascience&analysis',
      description: 'Data Science & Analysis',
      tooltip: 'Data Science & Analysis',
    },
    {
      name: 'Instrumentation',
      id: 94,
      code: 'instrumentation',
      description: 'Instrumentation',
      tooltip: 'Instrumentation',
    },
    {
      name: 'Expediting',
      id: 95,
      code: 'expediting',
      description: 'Expediting',
      tooltip: 'Expediting',
    },
    {
      name: 'All Disciplines',
      id: 99,
      code: 'all',
      description: 'All Disciplines',
      tooltip: 'All Disciplines',
    },
    {
      name: 'Others',
      id: 100,
      code: 'Others',
      description: 'Others',
      tooltip: 'Others',
    },
    {
      name: 'Welding',
      id: 102,
      code: 'welding',
      description: 'Welding',
      tooltip: 'Welding',
    },
    {
      name: 'Inspection',
      id: 103,
      code: 'inspection',
      description: 'Inspection',
      tooltip: 'Inspection',
    },
  ],
  Skill: [
    {
      name: 'Engineering & Design',
      id: 57,
      code: 'engineering&design',
      description: 'Engineering & Design',
      tooltip: 'Engineering & Design',
    },
    {
      name: 'CAD Modelling',
      id: 58,
      code: 'cadmodelling',
      description: 'CAD Modelling',
      tooltip: 'CAD Modelling',
    },
    {
      name: 'Drawing',
      id: 59,
      code: 'drawing',
      description: 'Drawing',
      tooltip: 'Drawing',
    },
    {
      name: 'Numerical Analysis (FEA, CFD)',
      id: 60,
      code: 'numericalanalysis',
      description: 'Numerical Analysis (FEA, CFD)',
      tooltip: 'Numerical Analysis (FEA, CFD)',
    },
    {
      name: 'Package management',
      id: 61,
      code: 'packagemanagement',
      description: 'Package management',
      tooltip: 'Package management',
    },
    {
      name: 'Manufacturing Support',
      id: 62,
      code: 'manufacturingsupport',
      description: 'Manufacturing Support',
      tooltip: 'Manufacturing Support',
    },
    {
      name: 'Machining Support',
      id: 63,
      code: 'machiningsupport',
      description: 'Machining Support',
      tooltip: 'Machining Support',
    },
    {
      name: 'Digital Marketing',
      id: 64,
      code: 'digitalmarketing',
      description: 'Digital Marketing',
      tooltip: 'Digital Marketing',
    },
    {
      name: 'Risk Management',
      id: 65,
      code: 'riskmanagement',
      description: 'Risk Management',
      tooltip: 'Risk Management',
    },
    {
      name: 'Business Analysis',
      id: 66,
      code: 'businessanalysis',
      description: 'Business Analysis',
      tooltip: 'Business Analysis',
    },
    {
      name: 'GD&T',
      id: 67,
      code: 'gd&t',
      description: 'GD&T',
      tooltip: 'GD&T',
    },
    {
      name: 'Interface Management',
      id: 68,
      code: 'interfacemanagement',
      description: 'Interface Management',
      tooltip: 'Interface Management',
    },
    {
      name: 'Product Development',
      id: 69,
      code: 'productdevelopment',
      description: 'Product Development',
      tooltip: 'Product Development',
    },
    {
      name: 'Project Management',
      id: 70,
      code: 'projectmanagement',
      description: 'Project Management',
      tooltip: 'Project Management',
    },
    {
      name: 'Geological Modelling',
      id: 71,
      code: 'geologicalmodelling',
      description: 'Geological Modelling',
      tooltip: 'Geological Modelling',
    },
    {
      name: 'Welding & Heat Treatment',
      id: 72,
      code: 'welding&heat',
      description: 'Welding & Heat Treatment',
      tooltip: 'Welding & Heat Treatment',
    },
    {
      name: 'Artificial Intelligent',
      id: 73,
      code: 'ai',
      description: 'Artificial Intelligent',
      tooltip: 'Artificial Intelligent',
    },
    {
      name: 'Machine Learning',
      id: 74,
      code: 'ml',
      description: 'Machine Learning',
      tooltip: 'Machine Learning',
    },
    {
      name: 'NDT (Non Destructive Testing)',
      id: 75,
      code: 'ndt',
      description: 'NDT (Non Destructive Testing)',
      tooltip: 'NDT (Non Destructive Testing)',
    },
    {
      name: 'Value Engineering',
      id: 76,
      code: 've',
      description: 'Value Engineering',
      tooltip: 'Value Engineering',
    },
    {
      name: 'Product Costing',
      id: 77,
      code: 'pc',
      description: 'Product Costing',
      tooltip: 'Product Costing',
    },
    {
      name: 'Six Sigma',
      id: 78,
      code: 'ss',
      description: 'Six Sigma',
      tooltip: 'Six Sigma',
    },
    {
      name: 'Others',
      id: 79,
      code: 'others',
      description: 'Others',
      tooltip: 'Others',
    },
  ],
  CareerLevels: [
    {
      name: 'Beginners',
      id: 471,
      code: 'beginners',
      description: 'Beginners',
      tooltip: 'Beginners',
    },
    {
      name: 'High Flyer',
      id: 472,
      code: 'highflyer',
      description: 'High Flyer',
      tooltip: 'High Flyer',
    },
    {
      name: 'Mid-Level',
      id: 473,
      code: 'mid-level',
      description: 'Mid-Level',
      tooltip: 'Mid-Level',
    },
    {
      name: 'Senior',
      id: 474,
      code: 'senior',
      description: 'Senior',
      tooltip: 'Senior',
    },
    {
      name: 'Manager',
      id: 475,
      code: 'manager',
      description: 'Manager',
      tooltip: 'Manager',
    },
    {
      name: 'Executive / Director',
      id: 476,
      code: 'executivedirector',
      description: 'Executive / Director',
      tooltip: 'Executive / Director',
    },
    {
      name: 'Others',
      id: 478,
      code: 'others',
      description: 'Others',
      tooltip: 'Others',
    },
  ],
  BlogCategory: [
    {
      name: 'Social Engineering',
      id: 1,
      code: 'Social',
      description:
        'Topics on "improve environment" to "save water" to "social responsibilities" to "corporate social responsibilities" to "engineers contribution to society" and many more to save our mother earth and nature',
      tooltip: 'Social Engineering',
    },
    {
      name: 'Smart Engineering',
      id: 2,
      code: 'Smart',
      description:
        'Various technical information and engineering product specific topics to share and learn',
      tooltip: 'Smart Engineering',
    },
    {
      name: 'Self Engineering',
      id: 3,
      code: 'Self',
      description:
        'Topics on "self-development" to "work life balance" to "importance of family and friends" to "individual time management" and associated topics will help every individual to introspection to be a nice human and better engineering professional',
      tooltip: 'Self Engineering',
    },
    {
      name: 'Strategy Engineering',
      id: 4,
      code: 'Strategy',
      description:
        'Topics ranging "efficient engineering solution" to "changing strategy for project execution" to "faster results and project time management" to "cost competitive scenarios" to "efficient and productivity driven results" to "change in industry to use digital technologies" to "latest applications that helps global collaboration" to "preferences and changes due to GIG industry" and many more that applies to engineers and engineering industries',
      tooltip: 'Strategy Engineering',
    },
    {
      name: 'Software for Engineers',
      id: 5,
      code: 'Software',
      description:
        'Topics explaining various engineering software use, application, benefits, new features that engineer’s need day in day out!',
      tooltip: 'Software for Engineers',
    },
  ],
  MentorSkill: [
    {
      name: 'Communication Skills',
      id: 1,
      code: 'communicationskills',
      description: 'Communication Skills',
      tooltip: 'Communication Skills',
    },
    {
      name: 'Presentation Skills',
      id: 2,
      code: 'presentationskills',
      description: 'Presentation Skills',
      tooltip: 'Presentation Skills',
    },
    {
      name: 'Work-life Balance',
      id: 3,
      code: 'work-lifebalance',
      description: 'Work-life Balance',
      tooltip: 'Work-life Balance',
    },
    {
      name: 'Professionalism',
      id: 4,
      code: 'professionalism',
      description: 'Professionalism',
      tooltip: 'Professionalism',
    },
    {
      name: 'Program Management',
      id: 5,
      code: 'programmanagement',
      description: 'Program Management',
      tooltip: 'Program Management',
    },
    {
      name: 'People Management',
      id: 6,
      code: 'peoplemanagement',
      description: 'People Management',
      tooltip: 'People Management',
    },
    {
      name: 'Project Management',
      id: 7,
      code: 'projectmanagement',
      description: 'Project Management',
      tooltip: 'Project Management',
    },
    {
      name: 'Business Strategy',
      id: 8,
      code: 'businessstrategy',
      description: 'Business Strategy',
      tooltip: 'Business Strategy',
    },
    {
      name: 'Productivity / Efficiency Improvement',
      id: 9,
      code: 'productivityefficiencyimprovement',
      description: 'Productivity / Efficiency Improvement',
      tooltip: 'Productivity / Efficiency Improvement',
    },
    {
      name: 'Time Management',
      id: 10,
      code: 'timemanagement',
      description: 'Time Management',
      tooltip: 'Time Management',
    },
    {
      name: 'Leadership',
      id: 11,
      code: 'leadership',
      description: 'Leadership',
      tooltip: 'Leadership',
    },
    {
      name: 'Entrepreneurship',
      id: 12,
      code: 'entrepreneurship',
      description: 'Entrepreneurship',
      tooltip: 'Entrepreneurship',
    },
    {
      name: 'Career Planning',
      id: 13,
      code: 'careerplanning',
      description: 'Career Planning',
      tooltip: 'Career Planning',
    },
    {
      name: 'Ethics & Integrity',
      id: 14,
      code: 'ethicsintegrity',
      description: 'Ethics & Integrity',
      tooltip: 'Ethics & Integrity',
    },
    {
      name: 'Networking',
      id: 15,
      code: 'networking',
      description: 'Networking',
      tooltip: 'Networking',
    },
    {
      name: 'Customer Satisfaction',
      id: 17,
      code: 'customersatisfaction',
      description: 'Customer Satisfaction',
      tooltip: 'Customer Satisfaction',
    },
    {
      name: 'Planning & Resource Management',
      id: 18,
      code: 'planningresourcemanagement',
      description: 'Planning & Resource Management',
      tooltip: 'Planning & Resource Management',
    },
    {
      name: 'Finance & Commercial Awareness',
      id: 19,
      code: 'financecommercialawareness',
      description: 'Finance & Commercial Awareness',
      tooltip: 'Finance & Commercial Awareness',
    },
    {
      name: 'Innovation & Research',
      id: 20,
      code: 'innovationresearch',
      description: 'Innovation & Research',
      tooltip: 'Innovation & Research',
    },
    {
      name: 'Sales management',
      id: 21,
      code: 'salesmanagement',
      description: 'Sales management',
      tooltip: 'Sales management',
    },
    {
      name: 'Teamwork',
      id: 31,
      code: 'teamwork',
      description: 'Teamwork',
      tooltip: 'Teamwork',
    },
    {
      name: 'Self-Development',
      id: 22,
      code: 'selfdevelopment',
      description: 'Self-Development',
      tooltip: 'Self-Development',
    },
    {
      name: 'Finance',
      id: 32,
      code: 'finance',
      description: 'Finance',
      tooltip: 'Finance',
    },
    {
      name: 'Project Planning',
      id: 25,
      code: 'projectplanning',
      description: 'Project Planning',
      tooltip: 'Project Planning',
    },
    {
      name: 'Stress Management',
      id: 26,
      code: 'stressmanagement',
      description: 'Stress Management',
      tooltip: 'Stress Management',
    },
    {
      name: 'Emotional Intelligence',
      id: 27,
      code: 'emotionalintelligence',
      description: 'Emotional Intelligence',
      tooltip: 'Emotional Intelligence',
    },
    {
      name: 'Parenting',
      id: 24,
      code: 'parenting',
      description: 'Parenting',
      tooltip: 'Parenting',
    },
    {
      name: 'Anger Management',
      id: 28,
      code: 'angermanagement',
      description: 'Anger Management',
      tooltip: 'Anger Management',
    },
    {
      name: 'Lifestyle Improvement',
      id: 30,
      code: 'lifestyleimprovement',
      description: 'Lifestyle Improvement',
      tooltip: 'Lifestyle Improvement',
    },
    {
      name: 'Others',
      id: 33,
      code: 'others',
      description: 'Others',
      tooltip: 'Others',
    },
  ],
  SeminarCategories: [
    {
      name: 'Seminar',
      id: 1,
      code: 'seminar',
      description: 'Seminar',
      tooltip: 'Seminar',
    },
    {
      name: 'Conference',
      id: 2,
      code: 'conference',
      description: 'Conference',
      tooltip: 'Conference',
    },
    {
      name: 'Workshop',
      id: 3,
      code: 'Workshop',
      description: 'Workshop',
      tooltip: 'Workshop',
    },
    {
      name: 'Webinar',
      id: 4,
      code: 'Webinar',
      description: 'Webinar',
      tooltip: 'Webinar',
    },
  ],
  Currencies: [
    {
      toolTip: 'euro',
      currencyCode: 'EUR',
      currencySymbol: '€',
      isActive: true,
      description: 'euro',
      id: 'EUR',
    },
    {
      toolTip: 'rupee',
      currencyCode: 'INR',
      currencySymbol: '₹',
      isActive: true,
      description: 'rupee',
      id: 'INR',
    },
    {
      toolTip: 'dollar',
      currencyCode: 'USD',
      currencySymbol: '$',
      isActive: true,
      description: 'dollar',
      id: 'USD',
    },
  ],
};
